
import {computed, defineComponent, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {ErrorMessage, Field, Form} from "vee-validate";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import MasterConfig from "@/core/config/MasterConfig";
import {dateConvert} from "@/core/filters/datatime";
import useCurrencyInput from "vue-currency-input";
import {commonf} from "@/core/filters/common";

export default defineComponent({
  name: "account-settings",
  data: function () {
    return {
      myAvatar : "media/avatars/blank.png",
    }
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  methods: {
    ...dateConvert.method,
    ...commonf,
    myMeth(e){
      const file = e.target.files[0];
      this.myAvatar = URL.createObjectURL(file);
      this.myAvatarFile = e.target.files[0].name;
      this.myFileUplaod();
      console.log(this.myAvatarFile);
    },

    removeImage () {
      this.myAvatar = "media/avatars/blank.png";
    },
    async myFileUplaod() {
      /*
      let formData = new FormData();
     // let myImgFile = (<HTMLInputElement>document.getElementById('accountAvatarFile')).files[0];
      formData.append("file", (<HTMLInputElement>document.getElementById('accountAvatarFile')).files[0]);
      await fetch(MasterConfig.general.uploadPath, {
        method: "POST",
        body: formData
      });

       */
    }
  },
  computed: {},
  setup() {
    const route = useRoute();
    const id = route.params.id;
    const payload = {
      ID: id
    }
    let today = new Date().toLocaleDateString()

    today = (dateConvert.method.convertToDateYM(today));
    store.dispatch(Actions.USER_DETAILS, payload);
    store.dispatch(Actions.COMPANY_BRANCHES_LIST, {});
    store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
    store.dispatch(Actions.COMPANY_TITLES_LIST, {});
    store.dispatch(Actions.SYSTEM_USER_GROUP_LISTALL, {});

    const myDetails = computed(() => {
      return store.getters.userDetails;
    });
    const myBranchList = computed(() => {
      return store.getters.BranchList;
    });
    const myDepartmentList = computed(() => {
      return store.getters.DepartmentsList;
    });
    const myTitleList = computed(() => {
      return store.getters.TitleList;
    });
    const myUserGroupList = computed(() => {
      return store.getters.SystemUserGroupList;
    });
    const submitButton1 = ref<HTMLElement | null>(null);
    const submitButton2 = ref<HTMLElement | null>(null);
    const submitButton3 = ref<HTMLElement | null>(null);
    const submitButton4 = ref<HTMLElement | null>(null);
    const submitButton5 = ref<HTMLElement | null>(null);
    const deactivateAccountButton = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);
    const accountIsActive = ref(null)
    const accountFinish = ref(null)
    const workWageCur = ref(null)
    const businessTitle = ref(null)
    const passwordDate = ref("1")


    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const StartDate =  ref(null);
    let myAvatarFile =  ref("nochange");
    let deactivate =  ref(null);

    let isActive;
    let isFinish;

    let isActiveStatus = computed(() => {
      return (myDetails.value.is_active == 'active') ? true : false;
    });

    let isFinishStatus = computed(() => {
      return (myDetails.value.FinishDate == null) ? true : false;
    });

    const imgChangeStatus = computed(() => {
      return (myDetails.value.Avatar) ? false : true;
      //return (myDetails.value.FinishDate == null) ? true : false;
    });

    const profileDetailsValidator = Yup.object().shape({
      fname: Yup.string().required().label("İsim"),
      lname: Yup.string().required().label("Soyisim"),
      accountCity: Yup.string().required().label("Şehir"),
      accountEmail: Yup.string().required().email().label("Email"),
      accountMobile: Yup.string().required().label("Telefon"),
    });

    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const changePosition = Yup.object().shape({
      businessTitle: Yup.string().required().label("Title"),
    });

    const deactiveAccount = Yup.object().shape({
      deactivate: Yup.string().required().label("Hesap Silme Oanyı"),
    });

    const changePassword = Yup.object().shape({
      newpassword: Yup.string().label("Plable").matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Şifre en az 8 karakter ve büyük harf - küçük harf - numara  ve özel karakter('@',# vs) içermelidir."
      ),
      confirmpassword: Yup.string()
          .min(4)
          .required()
          .oneOf([Yup.ref("newpassword"), null], "Girilen şifreler aynı değil")
          .label("Password Confirmation"),
    });


    const saveChanges1 = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        if(myAvatarFile.value=="nochange")myAvatarFile.value =null;
        const payload1 = {
          ID: myDetails.value.ID,
          //Avatar: document.getElementById("accountAvatarFile").files[0].name,
          Name: myDetails.value.Name,
          Lastname: myDetails.value.Lastname,
          City: myDetails.value.City,
          Country: myDetails.value.Country,
          Language: myDetails.value.Language,
          Mobile: myDetails.value.Mobile,
          Email: myDetails.value.Email,
          Avatar: myAvatarFile.value,
        }
        console.log(payload1);

        store.dispatch(Actions.USER_EDIT, payload1)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButton1.value?.removeAttribute("data-kt-indicator");
            }).catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton1.value?.removeAttribute("data-kt-indicator");
            });
      }
    };

    const saveChanges2 = () => {
      if (submitButton2.value) {
        // Activate indicator
        submitButton2.value.setAttribute("data-kt-indicator", "on");
        isActive = (accountIsActive.value.checked) ? 'active' : 'passive';
        isFinish = null;
        isFinish = (!accountFinish.value.checked) ? today : "set_null";
        let FinishDate = (!accountFinish.value.checked) ? today : "";
        const payload2 = {
          ID: myDetails.value.ID,
          is_active: isActive,
          FinishDate: FinishDate,
          setFinishDate: isFinish,

        }

        store.dispatch(Actions.USER_EDIT, payload2)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButton2.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton2.value?.removeAttribute("data-kt-indicator");
            });

      }
    };

    const saveChanges3 = () => {
      if (submitButton3.value) {
        submitButton3.value.setAttribute("data-kt-indicator", "on");
        const payload3 = {
          ID: myDetails.value.ID,
          BranchID: myDetails.value.BranchID,
          DepartmentID: myDetails.value.DepartmentID,
          TitleID: myDetails.value.TitleID,
          Type: myDetails.value.Type
        }
        console.log(payload3);
        store.dispatch(Actions.USER_EDIT, payload3)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButton3.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton3.value?.removeAttribute("data-kt-indicator");
            });

      }
    };

    const saveChanges4 = () => {
      if (submitButton4.value) {
        // Activate indicator
        submitButton4.value.setAttribute("data-kt-indicator", "on");
        const payload4 = {
          ID: myDetails.value.ID,
          UserGroupID: myDetails.value.UserGroupID,
        }

        store.dispatch(Actions.USER_EDIT, payload4)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButton4.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton4.value?.removeAttribute("data-kt-indicator");
            });

      }
    };

    const saveChanges5 = () => {
      if (submitButton5.value) {
        // Activate indicator
        //console.log(myDetails.value.Wage.replace(/[^\d.-]/g, ''));
        //console.log(commonf.strToDecimal(myDetails.value.Wage));
        submitButton5.value.setAttribute("data-kt-indicator", "on");
        const payload5 = {
          ID: myDetails.value.ID,
          Wage: commonf.strToDecimal(myDetails.value.Wage),
          WageCur: myDetails.value.WageCur,
          ShiftType: myDetails.value.ShiftType,
          StartDate: dateConvert.method.convertToDateYM(StartDate.value.value),
          WageType: myDetails.value.WageType,
          BankAccount: myDetails.value.BankAccount,
        }
        console.log(payload5);

        store.dispatch(Actions.USER_EDIT, payload5)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              submitButton5.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              submitButton5.value?.removeAttribute("data-kt-indicator");
            });

      }
    };

    const deactivateAccount = () => {
      if (deactivateAccountButton.value) {
        // Activate indicator
        deactivateAccountButton.value.setAttribute("data-kt-indicator", "on");

        const payload6 = {
          ID: myDetails.value.ID,
        }
        console.log(payload6);
        store.dispatch(Actions.USER_DELETE, payload6)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully delete
                //router.push({name: "userlist"});
                this.$router.push({name: "userlist"});
              });
              deactivateAccountButton.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              deactivateAccountButton.value?.removeAttribute("data-kt-indicator");
            });
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute("data-kt-indicator");

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");


        const payload1 = {
          ID: myDetails.value.ID,
          Password: myDetails.value.Password,
          PasswordDate: passwordDate.value,
        }
        console.log(payload1)
        store.dispatch(Actions.USER_EDIT, payload1)
            .then(() => {
              Swal.fire({
                text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Geri Dön!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                //router.push({name: "userlist"});
              });
              updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
              updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            });


        /*
        setTimeout(() => {
          updatePasswordButton.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: "Password is successfully changed!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            passwordFormDisplay.value = false;
          });
        }, 2000);
        */
      }
    };
    /*
        const removeImage = () => {
          profileDetails.value.avatar = "media/avatars/blank.png";
        };
    */
    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    store.dispatch(Actions.CITY_LIST, {});
    const myCities = computed(() => {
      return store.getters.CityList;
    });
    const filteredCity = computed(() => {
      if (myCities.value.data != null) {
        return (myCities.value.data).filter(citiesItem =>
            citiesItem.Country == (myDetails.value.Country).toLowerCase()
        );
      } else {
        return true;
      }
    });


    const filteredTitle = computed(() => {
      if (myTitleList.value.data != null) {
        return (myTitleList.value.data).filter(title =>
            title.DepartmentID == (myDetails.value.DepartmentID).toLowerCase()
        );
      } else {
        return true;
      }
    });

    let frmtStartDate = ref(() => {
      return dateConvert.method.convertToDate(frmtStartDate);
    });


    const myAvatarPath = computed(() => {
      return MasterConfig.general.imagePath + myDetails.value.Avatar;
    });



    const imageChange = (status) => {
      if(imgChangeStatus.value==false) myAvatarFile.value = "delete"
      myDetails.value.Avatar= null;
      //imgChangeStatus.value = !imgChangeStatus.value;
    };

    const curValue = () => {
      options.currency = myDetails.value.WageCur;
      setOptions(options);
    };
    const options = {
      locale: 'en-US',
      currency: (myDetails.value.WageCur) ? myDetails.value.WageCur : "TRY" ,
    }
    let {formattedValue, inputRef, setOptions } = useCurrencyInput(
        options
    );

    const cntTitle = () => {
      myDetails.value.TitleID=null;
    };

    return {
      id,
      myDetails,
      myBranchList,
      myDepartmentList,
      myTitleList,
      myUserGroupList,
      myCities,
      filteredCity,
      filteredTitle,
      MasterConfig,
      myAvatarPath,
      isActiveStatus,
      isFinishStatus,
      isActive,
      isFinish,
      StartDate,
      frmtStartDate,
      accountIsActive,
      accountFinish,
      businessTitle,
      inputRef,
      formattedValue,
      options,
      workWageCur,
      curValue,
      passwordDate,
      imgChangeStatus,
      myAvatarFile,
      imageChange,
      cntTitle,
      deactivate,
      submitButton1,
      submitButton2,
      submitButton3,
      submitButton4,
      submitButton5,
      deactivateAccountButton,
      saveChanges1,
      saveChanges2,
      saveChanges3,
      saveChanges4,
      saveChanges5,
      deactivateAccount,
      //profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      //removeImage,
      profileDetailsValidator,
      changeEmail,
      changePassword,
      changePosition,
      deactiveAccount,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
    };
  },
});
